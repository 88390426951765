import { ImageType, FilterType, CameraType } from 'helpers/types';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as apiAction from './api.action';

const useApi = () => {
  const dispatch = useDispatch();

  const { filterOptions, cameras, images, showLeftFilter, cameraMapBound, tags, tag_assigns, rangeSelect } = useSelector((state: any) => state.apiReducer);

  const setShowLeftFilter = useCallback(
    (payload:boolean) => {
      dispatch(apiAction.setShowLeftFilter(payload));
    },
    [dispatch],
  );

  const setCameraMapBound = useCallback(
    (payload:any) => {
      dispatch(apiAction.setCameraMapBound(payload));
    },
    [dispatch],
  );

  const setFilterOption = useCallback(
    (payload:FilterType) => {
      dispatch(apiAction.setFilterOption(payload));
    },
    [dispatch],
  );

  const setCameras = useCallback(
    (checked:CameraType[]) => {
      dispatch(apiAction.setCameras(checked));
    },
    [dispatch],
  );

  const setImages = useCallback(
    (checked:ImageType[]) => {
      dispatch(apiAction.setImages(checked));
    },
    [dispatch],
  );

  const setTags = useCallback(
    (tag:any[]) => {
      dispatch(apiAction.setTags(tag));
    },
    [dispatch],
  );

  const setTagAssigns = useCallback(
    (assigns:any[]) => {
      dispatch(apiAction.setTagAssigns(assigns));
    },
    [dispatch],
  );

  const setRangeSelect = useCallback(
    (payload:any) => {
      dispatch(apiAction.setRangeSelect(payload));
    },
    [dispatch],
  );
  return { cameras, images, filterOptions, showLeftFilter, cameraMapBound, tags, tag_assigns, rangeSelect, setFilterOption, setCameras, setImages, setShowLeftFilter, setCameraMapBound, setTags, setTagAssigns, setRangeSelect };
};

export default useApi;
