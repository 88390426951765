import { CameraType, FilterType, ImageType } from 'helpers/types';
import { createAction } from 'redux-act';

export const setFilterOption: any = createAction('setFilterOption', (filterOptions:FilterType) => ({ filterOptions }));
export const setShowLeftFilter: any = createAction('setShowLeftFilter', (showLeftFilter:Boolean) => ({ showLeftFilter }));
export const setCameraMapBound: any = createAction('setCameraMapBound', (cameraMapBound:any) => ({ cameraMapBound }));

export const setCameras:any = createAction('setCameras', (cameras:CameraType[]) => ({ cameras }));
export const setImages:any = createAction('setImages', (images:ImageType[]) => ({ images }));
export const setTags:any = createAction('setTags', (tags:any[]) => ({ tags }));
export const setTagAssigns:any = createAction('setTagAssigns', (tag_assigns:any[]) => ({ tag_assigns }));
export const setRangeSelect:any = createAction('setRangeSelect', (rangeSelect:any) => ({ rangeSelect }));
