import { DownloadOutlined, PlayCircleOutlined, SendOutlined, StarOutlined } from '@ant-design/icons';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ThemeProvider } from '@mui/material';
import { Button, Col, DatePicker, Divider, Form, Modal, notification, Radio, Row, Select, Spin, TimePicker, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import ImageEditTag from 'components/image/ImageEditTag';
import ImageFilterTag from 'components/image/ImageFilterTag';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import outerTheme from 'theme/mui';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const FilterImage = (props) => {
  const [trashCounter, setTrashCounter] = useState(0);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const { auth: { profile } } = useAuth();
  const { images, filterOptions, tags, rangeSelect, setImages, setFilterOption, setTags, setTagAssigns, setRangeSelect } = useApi();
  const [loading, setLoading] = useState(false);
  const [openHD, setOpenHD] = React.useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editCategories, setEditCategories] = useState([]);
  const { md } = useBreakpoint();

  useEffect(() => {
    form.setFieldsValue({ filterOptions });
    form.setFieldsValue({ length: filterOptions.length, category: filterOptions.category });
    setSelectAll(false);
  }, [filterOptions]);

  useEffect(() => {
    if (profile) {
      http_load_tags();
      http_load_categories();
    }
  }, [profile]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (trashCounter > 0) {
        setTrashCounter(trashCounter - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [trashCounter]);

  const onValuesChange = (values, allvalues) => {
    setFilterOption({ ...filterOptions, ...allvalues, user_id: profile.user_id });
  };

  const onSelectTag = (value) => {
    setFilterOption({ ...filterOptions, tag: value });
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    setFilterOption({ ...filterOptions, fromDate: dateString[0], toDate: dateString[1] });
  };

  const onChangeTime = (date: any, dateString: string[]) => {
    if (date) {
      const start = date[0].format('H');
      const end = date[1].format('H');
      if (start == end) {
        notification.open({
          message: 'Please select different time',
        });
        return;
      }
      setFilterOption({ ...filterOptions, fromHour: start, toHour: end });
    } else {
      setFilterOption({ ...filterOptions, fromHour: -1, toHour: -1 });
    }
  };

  const onClickSelectAll = () => {
    const selectedState = !selectAll;
    images.map((image) => {
      image.selected = selectedState;
      image.hovered = selectedState;
      return image;
    });
    setImages(images);
    setSelectAll(selectedState);
    props.initSelectIndex();
  };
  const onClickrangeSelect = () => {
    const selectedState = !rangeSelect;
    setRangeSelect(selectedState);
  };

  // http edit
  const onChangeCamera = (e) => {
    const ids: any = [];
    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    httpEdit(ids, 'camera_id', e, () => {
      let shouldMove = true;
      if (filterOptions.camera_id == '0' || filterOptions.camera_id == undefined) {
        shouldMove = false;
      }
      let newImages = [...images];
      if (shouldMove) {
        newImages = newImages.filter((item) => !item.selected);
      } else {
        const cam = props.cameraArr.find((item) => item.id == e);
        newImages.map((item) => {
          if (item.selected) {
            item.camera_id = cam.id;
            item.camera_name = cam.name;
            item.selected = false;
          }
          return item;
        });
      }
      setImages(newImages);
    });
  };

  const onChangeCategory = (e) => {
    const ids: any = [];

    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    httpEdit(ids, 'category', e, () => {
      let shouldMove = false;
      if (filterOptions.category == 'All' || filterOptions.category == undefined) {
        shouldMove = false;
      } else if ((filterOptions.category == 'Deer' && filterOptions.antlered == 0)) {
        if (e.includes('Deer')) {
          shouldMove = false;
        } else {
          shouldMove = true;
        }
      } else {
        shouldMove = true;
      }
      let newImages = [...images];
      if (shouldMove) {
        newImages = newImages.filter((item) => !item.selected);
      } else if (e.includes('Deer')) {
        const arr = e.replace(/[)(]/g, '').split(' ');
        const cate = arr[0];
        const cate_sub = arr[1];
        newImages.map((item) => {
          if (item.selected) {
            item.category = cate;
            item.category_sub = cate_sub;
            item.selected = false;
          }
          return item;
        });
      } else {
        newImages.map((item) => {
          if (item.selected) {
            item.category = e;
            item.selected = false;
          }
          return item;
        });
      }
      setImages(newImages);
      notification.open({
        message: 'Your Change was Successful',
      });
    });
  };

  const onChangeTag = (tag, category, type) => {
    if (category == undefined) {
      category = 'None';
    }
    // console.log('category', category);
    const ids: any = [];

    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    if (type == 2 && category != 'None') {
      // console.log('category', category);
      const params = {
        user_id: profile.user_id,
        category,
        tag,
      };

      Api.IMAGE_TAG_ASSIGN_ADD(params).then((() => {
        http_load_tags();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }

    httpEdit(ids, 'tag', tag, () => {
      let shouldMove = true;
      if (filterOptions.tag == '' || filterOptions.tag == undefined) {
        shouldMove = false;
      }
      let newImages = [...images];
      if (shouldMove) {
        newImages = newImages.filter((item) => !item.selected);
      } else {
        // console.log(shouldMove);
        // console.log(category);
        newImages.map((item) => {
          if (item.selected) {
            item.tag = tag;
            if (category == 'None') {
              item.selected = false;
            }
          }
          return item;
        });
      }
      setImages(newImages);
      if (type == 2) {
        http_load_tags();
      }

      if (category != 'None') {
        onChangeCategory(category);
      } else {
        notification.open({
          message: 'Your Change was Successful',
        });
      }
    });
  };

  const httpTrash = () => {
    setTrashCounter(0);
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      const params = {
        ids,
      };

      Api.IMAGE_TRASH(params).then((() => {
        props.reloadPage();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const httpEdit = (ids, field, value, callback) => {
    const params = {
      ids,
      field,
      value,
    };

    Api.IMAGE_EDIT(params).then((() => {
      callback();
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const http_load_tags = () => {
    Api.IMAGE_TAG_ASSIGN_ALL().then(((res: any) => {
      const jsonObject = JSON.parse(res.text);
      setTags(jsonObject.tags);
      setTagAssigns(jsonObject.assigns);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const http_load_categories = () => {
    Api.DETECTION_THRESHOLD().then(((res: any) => {
      const data = JSON.parse(res.text);
      const tcategories = data.filter((item) => item.enable == '1');
      const cates = tcategories.map((item) => item.category);
      cates.push('Other');
      setCategories(cates);
      if (cates.includes('Deer')) {
        const eCates = cates.filter((item) => item != 'Deer');
        eCates.unshift('Deer (Antlered)', 'Deer (Non antlered)');
        setEditCategories(eCates);
      } else {
        setEditCategories(cates);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const changeDeerSubCategroy = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, antlered: val });
  };

  const onChangeXlOnly = (e) => {
    setFilterOption({ ...filterOptions, xl_only: !filterOptions.xl_only, video_only: false });
  };

  const onChangeVideoOnly = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, video_only: !filterOptions.video_only, xl_only: false });
  };

  const onChangeFavoriteOnly = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, favorite_only: !filterOptions.favorite_only });
  };

  const onTrash = () => {
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      setTrashCounter(3);
    }
  };

  const onChangeTakenTime = (date, dateString) => {
    const ids: any = [];
    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    httpEdit(ids, 'time', dateString, () => {
      const newImages = [...images];
      newImages.map((item) => {
        if (item.selected) {
          if (item.manually_uploaded == '1') {
            item.time = dateString;
          }
          item.selected = false;
        }
        return item;
      });
      setImages(newImages);
    });
  };

  const onSendHD = () => {
    setOpenHD(false);
    const ids: any = [];
    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    const params = {
      ids,
      camera_id: filterOptions.camera_id,
    };
    setLoading(true);
    Api.IMAGE_REQUEST_HD_ONE_CAMERA(params).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: res.text,
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  return (
    <div className={classes.wrapper}>
      <ThemeProvider theme={outerTheme}>
        <Form form={form} style={{ marginTop: md ? 20 : 0 }} onValuesChange={onValuesChange}>
          <Form.Item label="Sort" name="order_by" style={{ marginBottom: 8 }} className={classes.formInputWrapper} initialValue="time">
            <Select dropdownStyle={{ position: 'fixed' }}>
              <Option key="id" value="id">Quick Sort</Option>
              <Option key="time" value="time">Taken Time</Option>
            </Select>
          </Form.Item>
          <Button type={rangeSelect ? 'primary' : 'default'} onClick={onClickrangeSelect} style={{ marginTop: 8, width: '100%' }}>
            {rangeSelect ? 'Range Deselect' : 'Range Select'}
          </Button>
          <Button type={selectAll ? 'primary' : 'default'} onClick={onClickSelectAll} style={{ marginTop: 8, width: '100%' }}>
            {selectAll ? 'Deselect All' : 'Select All'}
          </Button>
          <Divider style={{ marginBottom: 8 }}>Filter</Divider>
          <Button type={filterOptions.xl_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeXlOnly} icon={<img src="/icons/size-xl.png" alt="" className={classes.xl_img} />}>
            XL Images Only
          </Button>
          <Button type={filterOptions.video_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeVideoOnly} icon={<PlayCircleOutlined />}>
            Videos Only
          </Button>
          <Button type={filterOptions.favorite_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeFavoriteOnly} icon={<StarOutlined />}>
            Favorites Only
          </Button>
          {(filterOptions.category == 'Deer') && (
            <Radio.Group value={filterOptions.antlered} className={classes.categoryGroup} onChange={changeDeerSubCategroy}>
              <Row>
                <Col span={12}><Radio.Button className={classes.categoryBtn} value="0">All Deer</Radio.Button></Col>
                <Col span={12}><Radio.Button className={classes.categoryBtn} value="1">Bucks Only</Radio.Button></Col>
              </Row>
            </Radio.Group>
          )}
          <Form.Item name="camera_id" className={classes.formInputWrapper} initialValue="0">
            <Select dropdownStyle={{ position: 'fixed' }}>
              <Option key={0} value="0">All Cameras</Option>
              <OptGroup label="Cameras">
                {props.cameraArr.map((val: any) => (
                  <Option key={val.id} value={val.id}>{val.name}</Option>
                ))}
              </OptGroup>
              <OptGroup label="Camera Groups">
                {props.cameraGroups.map((val: any) => (
                  <Option key={val.id} value={`group${val.id}`}>{val.name}</Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item name="category" className={classes.formInputWrapper} initialValue="All">
            <Select dropdownStyle={{ position: 'fixed' }}>
              <Option key="category0" value="All">All Categories</Option>
              {categories.map((val, i) => (
                <Option key={`category${i + 1}`} value={val}>{val}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="tag" className={classes.formInputWrapper} initialValue="">
            <ImageFilterTag
              size="large"
              onSelectTag={onSelectTag}
              dropdownStyle={{ position: 'fixed' }}
            />
          </Form.Item>
          <Form.Item className={classes.formInputWrapper}>
            <div>
              <RangePicker style={{ width: '100%' }} onChange={onChangeDate} />
            </div>
          </Form.Item>
          <Form.Item className={classes.formInputWrapper}>
            <div>
              <TimePicker.RangePicker
                format="hh A"
                style={{ width: '100%' }}
                onChange={onChangeTime}
              />
            </div>
          </Form.Item>
        </Form>

        <Divider style={{ marginBottom: 8 }}>Edit</Divider>
        <Spin spinning={loading} size="large">
          <Form form={formEdit}>
            <div>
              <Form.Item name="to_camera" className={classes.formInputWrapper}>
                <Select placeholder="Change Camera To:" onChange={onChangeCamera} dropdownStyle={{ position: 'fixed' }}>
                  {props.cameraArr.map((val: any) => (
                    <Option key={val.id} value={val.id}>{val.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item name="to_category" className={classes.formInputWrapper}>
                <Select placeholder="Change Category To:" onChange={onChangeCategory} dropdownStyle={{ position: 'fixed' }}>
                  {editCategories.map((val, i) => (
                    <Option key={i} value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div style={{ marginTop: 16 }}>
              <Form.Item name="to_tag" className={classes.formInputWrapper}>
                <ImageEditTag tags={tags} onChangeTag={onChangeTag} />
              </Form.Item>
            </div>
            <div style={{ marginTop: 16 }}>
              <Tooltip title="Only manually uploaded images will be changed">
                <Form.Item name="edit_date" className={classes.formInputWrapper}>
                  <DatePicker
                    style={{
                      width: '100%',
                    }}
                    placeholder="Change Taken Time To:"
                    showTime
                    onChange={onChangeTakenTime}
                  />
                </Form.Item>
              </Tooltip>
            </div>
            {trashCounter > 0 && (
              <Button type="primary" onClick={httpTrash} className={classes.editTrash} danger>
                Confirm ({trashCounter})
              </Button>
            )}
            {trashCounter == 0 && (
              <Button type="primary" onClick={onTrash} className={classes.editTrash}>
                Trash
              </Button>
            )}
            {!(filterOptions.camera_id == '0' || filterOptions.camera_id == '' || filterOptions.camera_id == undefined) && !filterOptions.camera_id.includes('group') && (
              <div>
                <Button type={filterOptions.video_only ? 'primary' : 'default'} className={classes.editTrash} onClick={() => { setOpenHD(true); }} icon={<SendOutlined />}>
                  Request Video/XL Image
                </Button>
                <Modal
                  title="Do you wish to continue?"
                  visible={openHD}
                  onOk={onSendHD}
                  onCancel={() => { setOpenHD(false); }}
                >
                  <p>There is no additional cost to requesting XL versions of your images or full length videos. However, the larger pictures and longer videos will consume more of your monthly data allotment than the normal pictures. Please monitor your data usage page and contact support with any questions.</p>
                </Modal>
              </div>
            )}
          </Form>
        </Spin>
      </ThemeProvider>
      <Button icon={<DownloadOutlined />} onClick={() => props.download()} className={classes.editTrash} style={{ marginBottom: '16px' }}>
        Bulk Download
      </Button>
      <div style={{ display: 'flex', marginBottom: '4px' }}>
        <PhotoSizeSelectLargeIcon className={classes.img_video} style={{ marginRight: '8px', color: '#dc6300' }} />
        Image from Video
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
        <img src="/icons/size-xl.png" alt="" style={{ marginRight: '8px', width: '20px' }} />
        Extra Large Image
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
        <PlayCircleIcon className={classes.img_video} style={{ marginRight: '8px', color: '#dc6300' }} />
        Video
      </div>
    </div>
  );
};

export default FilterImage;
