import { CloseOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Grid, Modal, Radio, Row, Spin } from 'antd';
import * as Api from 'api';
import GalleryMediaBox from 'components/image/GalleryMediaBox';
import FilterImage from 'components/image/filterImage';
import ImageCard from 'components/image/imageCard';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useParams } from 'react-router-dom';
import useDownloader from 'react-use-downloader';
import JSZip from 'jszip';
import { format } from 'date-fns';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

interface ParamTypes {
  image_filter: string
}

const ImagePage = () => {
  const [selectStart, setSelectStart] = useState(-1);
  const [selectEnd, setSelectEnd] = useState(-1);
  const { auth: { profile } } = useAuth();
  const { image_filter } = useParams<ParamTypes>();
  const { md } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const { images, filterOptions, rangeSelect, setImages, setFilterOption, showLeftFilter, setShowLeftFilter, setRangeSelect } = useApi();
  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [cameraGroups, setCameraGroups] = useState<any[]>([]);
  const { download } = useDownloader();

  let groupImageDate = '';

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const newOptions = { order_by: 'time', length: 36, category: image_filter, camera_id: '', tag: '', fromDate: '', toDate: '', fromHour: -1, toHour: -1, video_only: 0, xl_only: 0, favorite_only: 0, antlered: '0' };
    setFilterOption({ ...filterOptions, ...newOptions });
  }, [image_filter]);

  const getImages = (more) => {
    setLoading(true);
    let lastId = -1;
    let lastTime = '';
    let category_api = filterOptions.category;
    if (filterOptions.antlered == '1') {
      category_api = 'Deer (Antlered)';
    }
    if (more && images.length > 0) {
      lastId = images[images.length - 1].id;
      lastTime = images[images.length - 1].time;
    }

    const params = {
      cameras: (filterOptions.camera_id == null || filterOptions.camera_id == 0) ? [] : [filterOptions.camera_id],
      categories: (category_api == null || category_api == 'All') ? [] : [category_api],
      tags: filterOptions.tag == 'All' ? [] : [filterOptions.tag],
      fromDate: filterOptions.fromDate == null ? '' : filterOptions.fromDate,
      toDate: filterOptions.toDate == null ? '' : filterOptions.toDate,
      fromHour: filterOptions.fromHour == null ? -1 : filterOptions.fromHour,
      toHour: filterOptions.toHour == null ? -1 : filterOptions.toHour,
      order_by: filterOptions.order_by,
      last_id: lastId,
      last_time: lastTime,
      sort: 'DESC',
      length: filterOptions.length,
      video_only: (filterOptions.video_only == null || !filterOptions.video_only) ? 0 : 1,
      favorite_only: (filterOptions.favorite_only == null || !filterOptions.favorite_only) ? 0 : 1,
      xl_only: (filterOptions.xl_only == null || !filterOptions.xl_only) ? 0 : 1,
      is_trash: 0,
    };

    Api.IMAGE_LOAD_ALL(params).then(((res: any) => {
      if (more) {
        setImages(images.concat(JSON.parse(res.text).data));
      } else {
        setImages(JSON.parse(res.text).data);
      }
      setLoading(false);
    })).catch(() => {
      setLoading(false);
    });
  };

  const http_favorite_change = (index, favorite) => {
    setLoading(true);
    const params = {
      id: index,
      value: favorite,
    };

    Api.IMAGE_FAVORITE_CHANGE(params).then((() => {
      setLoading(false);
    })).catch(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (images.length <= 0) {
      hideModal();
    }
  }, [images]);

  useEffect(() => {
    if (filterOptions) {
      reloadPage();
    }
  }, [filterOptions]);

  const onScroll = () => {
    getImages(true);
  };

  const hideModal = () => {
    setActiveCardIndex(-1);
  };

  const reloadPage = () => {
    setImages([]);
    getImages(false);
  };

  useEffect(() => {
    if (rangeSelect) {
      images.map((image, i) => {
        if (i < Math.max(selectStart, selectEnd) && i > Math.min(selectStart, selectEnd)) {
          image.selected = true;
          image.hovered = true;
        }
        return image;
      });
      if (selectStart >= 0 && selectEnd >= 0) {
        setRangeSelect(false);
      }
      setImages(images);
    }
  }, [selectEnd]);

  useEffect(() => {
    if (rangeSelect) {
      setSelectStart(-1);
      setSelectEnd(-1);
    }
  }, [rangeSelect]);

  const initSelectIndex = () => {
    setSelectStart(-1);
    setSelectEnd(-1);
    if (rangeSelect == true) {
      setRangeSelect(false);
    }
  };

  const selectCard = (index, value) => {
    if (selectStart == index) {
      setSelectStart(-1);
    }
    if (rangeSelect) {
      if (selectStart < 0) {
        setSelectStart(index);
      } else if (selectStart >= 0 && index != selectStart && selectEnd < 0) {
        setSelectEnd(index);
      }
    }
    images[index].selected = value;
    images[index].hovered = value;
    setImages(images);
  };

  const hoverHandler = (index, value) => {
    if (rangeSelect && selectStart >= 0 && selectEnd < 0) {
      images.map((image, i) => {
        if (i <= Math.max(selectStart, index) && i >= (selectStart != 0 ? Math.min(selectStart, index) : 0)) {
          image.hovered = value;
        }
        return image;
      });
      setImages(images);
    }
  };

  const downloadHandler = async () => {
    // images.map((image) => {
    //   if (image.selected) {
    //     download(`https://d7s85wyrr26qk.cloudfront.net/${image.image_name}`, image.image_name);
    //   }
    //   return image;
    // });
    const zip = new JSZip();
    const selectedImages = images.filter((image) => image.selected);

    await Promise.all(
      selectedImages.map(async (image) => {
        const response = await fetch(`https://d7s85wyrr26qk.cloudfront.net/${image.image_name}`);
        if (!response.ok) {
          console.error(`Failed to fetch image: ${image.image_name}`);
          return;
        }
        const blob = await response.blob();
        zip.file(image.image_name, blob);
      }),
    );

    try {
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const zipUrl = URL.createObjectURL(zipBlob);
      download(zipUrl, `hunt-${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}.zip`);
    } catch (error) {
      console.error('Error generating ZIP file:', error);
    }
  };

  const changeFavorite = (index, isFavorite) => {
    images[index].favorite = isFavorite;
    setImages(images);
    http_favorite_change(images[index].id, isFavorite);
  };

  const handleRightFilter = (willShow) => {
    setShowLeftFilter(willShow);
  };

  const changecategory = (e) => {
    const val = e.target.value;
    if (val == 'Deer') {
      setFilterOption({ ...filterOptions, category: val, antlered: '0' });
    } else {
      setFilterOption({ ...filterOptions, category: val });
    }
  };

  useEffect(() => {
    if (profile) {
      // get cameras and tags
      Api.CAMERAS_GROUPS().then(((res: any) => {
        const result = JSON.parse(res.text);
        setCameraArr(result.cameras);
        setCameraGroups(result.cameraGroups);
      })).catch(() => {
      });
    }
  }, [profile]);

  const onLastPage = () => {
    getImages(true);
  };

  return (
    <>
      <Row>
        {md && (
          <Col md={6} xxl={4}>
            <div className={classes.imageFilter}>
              <FilterImage
                cameraGroups={cameraGroups}
                cameraArr={cameraArr}
                reloadPage={() => reloadPage()}
                download={downloadHandler}
                initSelectIndex={initSelectIndex}
              />
            </div>
          </Col>
        )}
        {md == false && (
          <Drawer
            title="Filter & Edit"
            width={300}
            onClose={() => handleRightFilter(false)}
            visible={showLeftFilter}
            zIndex={900}
            handler={
              (
                <div className={classes.drawerHandle}>
                  {
                    showLeftFilter ? <CloseOutlined onClick={() => handleRightFilter(false)} /> : <FilterOutlined onClick={() => handleRightFilter(true)} />
                  }
                </div>
              )
            }
          >
            <FilterImage
              cameraGroups={cameraGroups}
              cameraArr={cameraArr}
              reloadPage={() => reloadPage()}
            />
          </Drawer>
        )}
        <Col md={18} xs={24} xxl={20} id="image_scroll_container" className={classes.imageScrollContainer}>
          <Radio.Group value={filterOptions.category} buttonStyle="solid" className={classes.categoryGroup} onChange={changecategory}>
            <Row>
              <Col span={md ? 3 : 6} key={1}><Radio.Button className={classes.categoryBtn} value="All">All</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={2}><Radio.Button className={classes.categoryBtn} value="Deer">Deer</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={3}><Radio.Button className={classes.categoryBtn} value="Hog">Hog</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={4}><Radio.Button className={classes.categoryBtn} value="Turkey">Turkey</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={5}><Radio.Button className={classes.categoryBtn} value="People">People</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={6}><Radio.Button className={classes.categoryBtn} value="Vehicle">Vehicle</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={7}><Radio.Button className={classes.categoryBtn} value="Predator">Predator</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={8}><Radio.Button className={classes.categoryBtn} value="Other">Other</Radio.Button></Col>
            </Row>
          </Radio.Group>
          <Spin spinning={loading} size="large" wrapperClassName={classes.content}>
            <InfiniteScroll
              dataLength={images.length}
              next={onScroll}
              hasMore={!loading}
              loader=""
              endMessage={
                (
                  <p style={{ textAlign: 'center' }}>
                    <b>No more images</b>
                  </p>
                )
              }
              scrollableTarget="image_scroll_container"
            >
              <Row className={classes.content}>
                {
                  images.map((e, i) => {
                    const date = e.time.split(' ')[0];
                    if (date == groupImageDate) {
                      return (
                        <Col xs={12} md={6} xxl={4} key={i} className={`${(e.hovered || e.selected) ? classes.hovered : ''}`} onMouseEnter={() => hoverHandler(i, true)} onMouseLeave={() => hoverHandler(i, false)}>
                          <ImageCard key={i} index={i} detail={e} onClick={(index) => { setActiveCardIndex(index); }} onSelect={(arg) => { selectCard(i, arg); }} onFavorite={(index, isFavorite) => changeFavorite(index, isFavorite)} />
                        </Col>
                      );
                    }
                    groupImageDate = date;
                    return (
                      <React.Fragment key={i}>
                        <Divider key={`Divider${i}`} orientation="left" className={classes.groupDivider}>{moment(groupImageDate).format('MMM D, Y')}</Divider>
                        <Col xs={12} md={6} xxl={4} key={i} className={`${(e.hovered || e.selected) ? classes.hovered : ''}`} onMouseEnter={() => hoverHandler(i, true)} onMouseLeave={() => hoverHandler(i, false)}>
                          <ImageCard key={i} index={i} detail={e} onClick={(index) => { setActiveCardIndex(index); }} onSelect={(arg) => { selectCard(i, arg); }} onFavorite={(index, isFavorite) => changeFavorite(index, isFavorite)} />
                        </Col>
                      </React.Fragment>
                    );
                  })
                }
              </Row>
            </InfiniteScroll>
          </Spin>
        </Col>
      </Row>
      <div className={classes.containerFloat}>
        <Link to="/imageUpload">
          <Button type="primary" shape="round" size="large" icon={<PlusOutlined />} className="addImageBtn">
            Add images
          </Button>
        </Link>
      </div>
      <Modal
        visible={activeCardIndex > -1}
        onOk={hideModal}
        onCancel={hideModal}
        width={1000}
        className={classes.mediaModal}
        bodyStyle={{ backgroundColor: 'transparent', padding: 0 }}
        footer={null}
        destroyOnClose
      >
        <GalleryMediaBox
          activeIndex={activeCardIndex}
          cameraArr={cameraArr}
          onLastPage={onLastPage}
          onFavorite={(index, isFavorite) => changeFavorite(index, isFavorite)}
        />
      </Modal>
    </>
  );
};

export default ImagePage;
